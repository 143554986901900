import React from "react"
import { Heading, Box, Image } from "theme-ui"
import Button from "../components/button"
import logoColor from "../images/dv-logo.svg"
import logoBlack from "../images/dv-logo-black.svg"
import logoWhite from "../images/donkervoort-logo-white.svg"

const StylesPage = () => (
  <main>
    <Box sx={{ p: 4 }}>
      <Heading as="h1">Heading as H1</Heading>
      <Heading as="h2">Heading as H2</Heading>
      <Heading as="h3">Heading as H3</Heading>
      <Heading as="h4">Heading as H4</Heading>
      <Heading as="h5">Heading as H5</Heading>
      <br />
      <h1>Heading H1</h1>
      <h2>Heading H2</h2>
      <h3>Heading H3</h3>
      <h4>Heading H4</h4>
      <h5>Heading H5</h5>
      <Button variant="primary">Primary button</Button>

      <Button variant="outline">Outline button</Button>
      <Box sx={{ background: "#000", p: 4, my: 4 }}>
        <Button variant="secondary">Secondary button</Button>
        <Button variant="outlineWhite">Outline white button</Button>
      </Box>
      <Button variant="arrowLink">Arrow link button</Button>
      <Box>
        <Image src={logoColor} sx={{ width: "300px" }} />
        <Image src={logoBlack} sx={{ width: "300px" }} />
      </Box>
      <Box sx={{ background: "#000" }}>
        <Image src={logoWhite} sx={{ width: "300px" }} />
      </Box>
    </Box>
  </main>
)

export default StylesPage
